import { createPinia,defineStore } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'


export const userInfoStore = defineStore('userInfo',{
  state: ()=>({
    //-登录状态
    isLogin: false,
    token:null,
    userName: null,
  }),
  //-开启持久化
  persist: true
});
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
export default pinia;