<template>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" @closed="onClose">
      <el-form :model="form">
        <el-form-item label="版本号" :label-width="formLabelWidth">
          <el-input v-model="planInfo.version" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="平台" :label-width="formLabelWidth">
          <el-input v-model="planInfo.platform" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input v-model="planInfo.details" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="任务链接" :label-width="formLabelWidth">
          <el-input v-model="planInfo.taskUrl" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="测试负责人" :label-width="formLabelWidth">
          <el-select v-model="planInfo.testerId1" placeholder="请选择人员">
            <el-option v-for="item in testerList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用例审核员" :label-width="formLabelWidth">
          <el-select v-model="planInfo.testerId2" placeholder="请选择人员">
            <el-option v-for="item in testerList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit(planInfo)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    onClose: Function,
    planId: Number,
    appId: Number,
  },
  watch: {
    visible: function (newVal) {
      this.dialogFormVisible = newVal;
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      testerList: [],
      planInfo: {},
      dialogTitle: "编辑测试计划",
    };
  },
  mounted() {
    this.getPlanInfo(this.planId);
    this.getTesterList();
  },
  methods: {
    getPlanInfo(planId) {
      if (planId > 0) {
        this.$axios
          .get("api/plan/info?planId=" + planId)
          .then((response) => {
            if (response.data.result == 1) {
              this.planInfo = response.data.data[0];
              this.dialogTitle = "编辑测试计划";
            } else {
              this.planInfo = {
                name: null,
                testerId1: 0,
                testerId2: 0,
                taskUrl: null,
                version: null,
                platform: null,
                details: null,
              };
              this.dialogTitle = "创建测试计划";
            }
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      } else {
        this.dialogTitle = "创建测试计划";
      }
    },
    getTesterList() {
      this.$axios.get("api/user/tester").then((response) => {
        this.testerList = response.data.data;
      });
    },
    changeTester1(id) {
      const article = { planId: this.planId, testerId: id };
      this.$axios.post("api/plan/changeTester1", article);
    },
    changeTester2(id) {
      const article = { planId: this.planId, testerId: id };
      this.$axios.post("api/plan/changeTester2", article);
    },
    submit(planInfo) {
      const article = {
        id: this.planId,
        testerId1: planInfo.testerId1,
        testerId2: planInfo.testerId2,
        version: planInfo.version,
        platform: planInfo.platform,
        details: planInfo.details,
        taskUrl: planInfo.taskUrl,
        appId: this.appId,
      };
      this.$axios.post("api/plan/update", article).then(() => {
        this.dialogFormVisible = false;
      });
    },
  },
};
</script>
