<template>
  <el-container style="scroll-behavior: unset">
    <el-header v-if="this.$route.path != '/login'">
      <el-row type="flex">
        <el-menu ref="IndexMenu" router :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" style="width: 100%">
          <el-menu-item index="/test">测试</el-menu-item>
          <el-menu-item index="/cases">用例管理</el-menu-item>
        </el-menu>
        <el-button v-if="!isLogin()" type="primary" @click="login">登录</el-button>
        <div style="display: flex; align-items: center; justify-content: center">
          <el-avatar v-if="isLogin()">{{ getUserName() }}</el-avatar>
          <el-button v-if="isLogin()" type="danger" @click="logout" style="margin-left: 16px">退出登录</el-button>
        </div>
      </el-row>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>
<script>
import { userInfoStore } from "../accout/user.js";
export default {
  data() {
    return {
      activeIndex: this.$route.path == "/" ? "/test" : this.$route.path,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      console.log(window.location.pathname);
    },
    isLogin() {
      return userInfoStore().isLogin;
    },
    getUserName() {
      let userName = userInfoStore().userName;
      if (userName != null && userName.length > 2) {
        return userName.slice(-2);
      } else {
        return userName;
      }
    },
    login() {
      this.activeIndex = "/login";
      this.$router.push("/login");
    },
    logout() {
      userInfoStore().isLogin = false;
      userInfoStore().token = null;
      userInfoStore().userName = null;
      this.activeIndex = "/login";
      this.$router.push("/login");
    },
  },
};
</script>
