import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import AppActivity from "./components/AppActivity.vue";
import CasesActivity from "./components/CasesActivity.vue";
import LoginActivity from "./components/LoginActivity.vue";
import pinia  from './accout/user'
import myAxios from './utils/myAxios';

Vue.use(ElementUI);
Vue.use(VueAxios,axios);
Vue.use(VueRouter);
Vue.prototype.$HOME_URL = 'http://localhost:8080/';
Vue.use(pinia);
Vue.use(myAxios);

const router = new VueRouter({
  routes:[
    {
      path:'/null',
      redirect:'/test'
    },
    {
      path:'/',
      redirect:'/test'
    },
    { path: '/test', component: AppActivity },
  { path: '/cases', component: CasesActivity },
  { path:'/login',component:LoginActivity}
  ]
});

new Vue({
  router,
  el: '#app',
  render: h => h(App)
});