<template>
  <el-container>
    <el-main class="main">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" style="width: 30%">
        <el-form-item label="账号" prop="pass">
          <el-input type="text" v-model="ruleForm.username" autocomplete="true"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.password" autocomplete="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm()">返 回</el-button>
          <el-button type="primary" @click="submitForm()">登 录</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import { userInfoStore } from "../accout/user.js";
export default {
  data() {
    return {
      ruleForm: {
        password: "",
        username: "",
      },
      rules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    resetForm() {
      this.$router.replace("/test");
    },
    submitForm() {
      let data = {
        username: this.ruleForm.username,
        password: this.ruleForm.password,
      };
      console.log(data);
      this.$axios.post("api/user/login", data).then((response) => {
        if (response.data.result == 1) {
          userInfoStore().token = response.data.data[0].token;
          userInfoStore().isLogin = true;
          userInfoStore().userName = response.data.data[0].name;
          console.log("登录成功:token=" + userInfoStore().token);
          this.$router.push("/test");
        } else {
          console.error("登录失败" + response.data);
        }
      });
    },
  },
};
</script>
<style>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
}
</style>
