<template>
  <div>
    <h4>{{ planInfo.name }}</h4>
    <el-row>
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col>
          <span>测试负责人：</span>
          <el-input placeholder="未选择人员" v-model="planInfo.tester1" :disabled="true" style="width: 200px"></el-input>
        </el-col>
        <el-button v-if="isLogin()" type="primary" size="small" icon="el-icon-circle-plus-outline" @click="showAddDialog">添加用例</el-button>
        <el-button v-if="isLogin()" type="primary" icon="el-icon-edit" @click="dialogVisible = true">编辑</el-button>
        <el-button v-if="isLogin()" type="danger" icon="el-icon-delete" @click="deletePlan()">删除</el-button>
        <el-button type="success" @click="jumpTaskUrl(planInfo.taskUrl)" :disabled="planInfo.taskUrl == null">跳转到任务<i class="el-icon-d-arrow-right el-icon--right"></i></el-button>
      </el-row>
      <el-row :gutter="20" style="margin-top: 16px; width: 100%">
        <el-col :span="10">
          <span>用例审核员：</span>
          <el-input placeholder="未选择人员" v-model="planInfo.tester2" :disabled="true" style="width: 200px"></el-input>
          <el-select v-model="planInfo.checkId" placeholder="审核状态" @change="changeCheck(planInfo.checkId)">
            <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-divider></el-divider>
    </el-row>
    <el-row>
      <el-col>
        <el-row>
          <el-alert v-for="tipInfo in tipList" :key="tipInfo.id" type="warning" effect="dark" show-icon style="margin-top: 16px"> {{ tipInfo.name }}</el-alert>
        </el-row>
      </el-col>
      <el-col>
        <el-divider></el-divider>
      </el-col>
      <el-col>
        <el-collapse v-model="activeNames" @change="handleChange" style="width: 100%; text-align: center">
          <el-collapse-item :key="group.id" v-for="group in groupList" :title="group.name" :name="group.id">
            <CasesTable :planId="planId" :groupId="group.id" />
          </el-collapse-item>
        </el-collapse>
      </el-col>
    </el-row>
    <PlanEditDialog v-if="planInfo != {}" :visible="dialogVisible" :planId="planId" :onClose="onCloseEditDialog" />
    <PlanAddCasesDialog :visible="addCasesDialogVisible" :onClose="onCloseCasesAddDialog" :planId="planId" />
  </div>
</template>
<script>
import { userInfoStore } from "../accout/user.js";
import CasesTable from "./PlanCasesTable.vue";
import PlanEditDialog from "./PlanEditDialog.vue";
import PlanAddCasesDialog from "./PlanAddCasesDialog.vue";
export default {
  components: {
    CasesTable,
    PlanEditDialog,
    PlanAddCasesDialog,
  },
  props: {
    planId: Number,
    planName: String,
    myCallback: Function,
    appId: Number,
  },
  watch: {
    planId: function () {
      this.getGroupList(this.planId);
      this.getPlanInfo(this.planId);
    },
  },
  data() {
    return {
      options: [
        {
          id: 0,
          value: "未审核",
        },
        {
          id: 1,
          value: "已通过",
        },
        {
          id: 2,
          value: "不通过",
        },
      ],
      planInfo: {},
      activeNames: ["1", "2", "3"],
      groupList: [],
      dialogVisible: false,
      addCasesDialogVisible: false,
      tipList: [],
    };
  },
  mounted() {
    this.getGroupList(this.planId);
    this.getPlanInfo(this.planId);
    this.getTipList();
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    getPlanInfo(planId) {
      if (planId > 0) {
        this.$axios
          .get("api/plan/info?planId=" + planId)
          .then((response) => {
            this.planInfo = response.data.data[0];
            console.log(this.planInfo);
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      }
    },
    getGroupList(planId) {
      this.$axios
        .get("api/plan/groups?planId=" + planId)
        .then((response) => {
          this.groupList = response.data.data;
          for (var i = 0; i < this.groupList.length; i++) {
            this.activeNames.push(this.groupList[i].id);
          }
        })
        .catch((error) => {
          console.error(error.response.data);
        });
    },
    getTipList() {
      this.$axios.get("api/tip/list").then((response) => {
        this.tipList = response.data.data;
      });
    },
    jumpTaskUrl(url) {
      window.open(url);
    },
    changeCheck(checked) {
      const article = { id: this.planId, checked: checked };
      this.$axios.post("api/plan/changeCheck", article);
    },
    onCloseEditDialog() {
      this.dialogVisible = false;
      this.getPlanInfo(this.planId);
      this.myCallback(this.appId);
    },
    showAddDialog() {
      this.addCasesDialogVisible = true;
    },
    onCloseCasesAddDialog() {
      this.addCasesDialogVisible = false;
      this.getGroupList(this.planId);
    },
    isLogin() {
      return userInfoStore().isLogin;
    },
    deletePlan() {
      this.$confirm("此操作将永久删除该测试计划, 是否继续?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const article = { id: this.planId };
          this.$axios.post("api/plan/delete", article).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$router.push({
              query: { appId: this.appId },
            });
            this.$router.go(0);
          });
        })
        .catch(() => {});
    },
  },
};
</script>
