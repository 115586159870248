<template>
  <el-dialog title="添加用例组" :visible.sync="dialogFormVisible" @closed="onClose">
    <el-transfer filterable v-model="groupListBase" :data="groupListOnline" :filter-method="filterMethod" filter-placeholder="请输入关键字" :titles="['可添加用例组', '当前用例组']"></el-transfer>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    onClose: Function,
    planId: Number,
    appId: Number,
  },
  watch: {
    visible: function (newVal) {
      this.dialogFormVisible = newVal;
      if (newVal) {
        this.getList();
      }
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      groupListBase: [],
      groupListOnline: [],
      value: [1, 4],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
    };
  },
  mounted() {},
  methods: {
    getList() {
      this.$axios.get("api/plan/groups?planId=" + this.planId).then((response) => {
        this.groupListBase.length = 0;
        for (let i = 0; i < response.data.data.length; i++) {
          let groupInfo = response.data.data[i];
          this.groupListBase.push(groupInfo.id);
        }
        this.$axios.get("api/plan/groups/not?planId=" + this.planId).then((response) => {
          this.groupListOnline.length = 0;
          for (let i = 0; i < response.data.data.length; i++) {
            let groupInfo = response.data.data[i];
            this.groupListOnline.push({
              key: groupInfo.id,
              label: groupInfo.name,
              disabled: this.groupListBase.includes(groupInfo.id),
            });
          }
        });
      });
    },
    submit() {
      const article = { planId: this.planId, groups: this.groupListBase };

      this.$axios.post("api/plan/add/group", article).then(() => {
        this.dialogFormVisible = false;
        this.onClose();
      });
    },
  },
};
</script>
