<template>
  <el-container style="height: 100%">
    <el-container>
      <el-aside>
        <div width="50px" style="background: #fafafa">
          <el-menu :default-active="appId + ''">
            <el-row type="flex" justify="start" style="margin-left: 16px; align-items: center; margin-right: 8px">
              <el-col>
                <h4>应用列表</h4>
              </el-col>
              <el-button v-if="isLogin()" type="primary" size="small" icon="el-icon-circle-plus-outline" @click="showAddDialog">创建应用</el-button>
            </el-row>
            <el-row>
              <el-menu-item :index="app.id + ''" :key="app.id" v-for="app in appList" @click="clickApp(app)">
                <el-image v-if="app.icon" :src="app.icon" style="width: 25px; height: 25px; margin-right: 8px"></el-image>
                <span>{{ app.name }}</span>
              </el-menu-item>
            </el-row>
          </el-menu>
        </div>
      </el-aside>
      <PlanPage ref="casesPageRef" :appId="appId" />
      <AppEditDialog :visible="dialogVisible" :onClose="onCloseEditDialog" />
    </el-container>
  </el-container>
</template>

<script>
import PlanPage from "./PlanPage.vue";
import AppEditDialog from "./AppEditDialog.vue";
import { userInfoStore } from "../accout/user.js";
export default {
  data() {
    return {
      appId: parseInt(this.$route.query.appId),
      appList: [],
      dialogVisible: false,
    };
  },
  components: {
    PlanPage,
    AppEditDialog,
  },
  mounted() {
    this.getAppList();
  },
  methods: {
    getAppList() {
      this.$axios
        .get("api/app/list")
        .then((response) => {
          this.appList = response.data.data;
        })
        .catch((error) => {
          console.error(error.response.data);
        });
    },
    clickApp(app) {
      this.appId = app.id;
      if (this.$route.query.appId != this.appId) {
        this.$router.push({
          query: { appId: app.id },
        });
        console.log(app.name, app.id, this.$refs.casesPageRef);
        this.$refs.casesPageRef.getPlanList(app.id);
      }
    },
    showAddDialog() {
      this.dialogVisible = true;
    },
    onCloseEditDialog() {
      this.dialogVisible = false;
      this.getAppList();
    },
    isLogin() {
      return userInfoStore().isLogin;
    },
  },
};
</script>
