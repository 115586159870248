<template>
  <div>
    <el-table ref="table" :data="tableData" border size="mini" :style="{ borderColor: '#888', width: '1500px' }" :cell-style="{ borderColor: '#888', width: '100%' }" :header-cell-style="{ borderColor: '#888', background: '#eef1f6', color: '#464646', width: '100%' }">
      <el-table-column prop="index" label="序号" width="50px"> </el-table-column>
      <el-table-column prop="module" label="模块" width="80px"> </el-table-column>
      <el-table-column prop="name" label="用例名"> </el-table-column>
      <el-table-column prop="priority" label="优先级" width="60px" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" :type="scope.row.priority === '无' ? 'info' : scope.row.priority === '低' ? '' : scope.row.priority === '中' ? 'success' : 'warning'" disable-transitions>{{ scope.row.priority }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="steps" label="操作步骤"> </el-table-column>
      <el-table-column prop="outcome" label="期待结果"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
      <el-table-column label="操作" width="179px" v-if="isLogin()">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showAddDialog(scope.row)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteCases(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <CasesEditDialog :casesId="casesId" :visible="dialogVisible" :onClose="onCloseEditDialog" />
  </div>
</template>
<script>
import CasesEditDialog from "./CasesEditDialog.vue";
import { userInfoStore } from "../accout/user.js";
export default {
  components: {
    CasesEditDialog,
  },
  props: {
    groupId: Number,
  },
  watch: {
    groupId: function () {
      this.fetchData();
    },
  },
  data() {
    return {
      dialogVisible: false,
      options: [
        {
          value: 0,
          label: "待测试",
          color: "#D8D8D8",
          textColor: "#fff",
        },
        {
          value: 1,
          label: "通过",
          color: "#00B14D",
          textColor: "#fff",
        },
        {
          value: 2,
          label: "不通过", //
          color: "#C10002",
          textColor: "#fff",
        },
        {
          value: 3,
          label: "忽略", //FFD966
          color: "#FFD966",
          textColor: "#fff",
        },
      ],
      tableData: [],
      casesId: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (!Number.isNaN(this.groupId)) {
        this.$axios
          .get("api/cases/list/group?groupId=" + this.groupId)
          .then((response) => {
            this.tableData = response.data.data;
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      }
    },
    formatter(row) {
      switch (row.priority) {
        case 0:
          return "无";
        case 1:
          return "低";
        case 2:
          return "中";
        default:
          return "高";
      }
    },
    getState(state) {
      switch (state) {
        case 0:
          return "未测试";
        case 1:
          return "通过";
        case 2:
          return "不通过";
        default:
          return "未知";
      }
    },
    getStateColor(row) {
      return getStateColor(row.state);
    },
    changeState(row) {
      const article = { id: row.id, state: row.state };
      const inner = this.$refs.table.$el.querySelectorAll(".el-input__inner")[row.index];
      inner.style.background = getStateColor(row.state);
      this.$axios.post("api/plan/changeState", article);
    },
    getStateClass(row) {
      switch (row.state) {
        case 0:
          return "base";
        case 1:
          return "pass";
        case 2:
          return "nopass";
        case 3:
          return "skip";
        default:
          return "base";
      }
    },
    onCloseEditDialog() {
      this.dialogVisible = false;
      this.fetchData();
    },
    showAddDialog(row) {
      this.casesId = row.id;
      this.dialogVisible = true;
      console.log("显示弹窗:" + this.casesId + "," + row.name);
    },
    isLogin() {
      return userInfoStore().isLogin;
    },
    deleteCases(row) {
      this.$confirm("此操作将永久删除该测试用例, 是否继续?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const article = { id: row.id };
          this.$axios.post("api/cases/delete", article).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.tableData = this.tableData.filter((item) => item.id != row.id);
          });
        })
        .catch(() => {});
    },
  },
};
function getStateColor(state) {
  switch (state) {
    case 0:
      return "#D8D8D8";
    case 1:
      return "#00B14D";
    case 2:
      return "#C10002";
    case 3:
      return "#ffd966";
    default:
      return "#FFD966";
  }
}
</script>
<style>
.my-select {
  .pass {
    .el-input__inner {
      color: #fff;
      background-color: #00b14d;
    }
  }
  .base {
    .el-input__inner {
      color: #1f1f1f;
      background-color: #d8d8d8;
    }
  }
  .nopass {
    .el-input__inner {
      color: #fff;
      background-color: #c10002;
    }
  }
  .skip {
    .el-input__inner {
      color: #1f1f1f;
      background-color: #ffd966;
    }
  }
}
</style>
