<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" @closed="onClose" width="800px">
    <el-form :model="appInfo" :rules="rules" ref="ruleForm" style="width: 50%">
      <el-form-item prop="name">
        <el-input v-model="appInfo.name" placeholder="应用名称"></el-input>
      </el-form-item>
      <el-form-item prop="icon">
        <el-input v-model="appInfo.icon" placeholder="图标链接"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" icon="el-icon-circle-plus" @click="submit(appInfo)">创 建</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    onClose: Function,
  },
  watch: {
    visible: function (newVal) {
      this.dialogFormVisible = newVal;
    },
  },
  data() {
    return {
      dialogTitle: "创建应用",
      dialogFormVisible: false,
      appInfo: {},
      rules: {
        name: [{ required: true, message: "请输入应用名称", trigger: "blur" }],
      },
    };
  },
  methods: {
    submit(appInfo) {
      this.$axios.post("api/app/add", appInfo).then(() => {
        this.dialogFormVisible = false;
        this.onClose();
      });
    },
  },
};
</script>
