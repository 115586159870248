// 创建 axios 实例
import axios from 'axios';
import { userInfoStore } from "../accout/user.js";
import { Notification } from 'element-ui';
// const HOME_URL = "http://127.0.0.1:8080";
const HOME_URL = "https://tester.eyewind.cn";
const service = axios.create({
  baseURL: HOME_URL, // 设置请求的基地址
  timeout: 5000, // 设置请求超时时间（毫秒）
});

service.interceptors.request.use(
    config => {
      config.headers['token'] = userInfoStore().token;
      return config;
    },
    error => {
      // 请求错误处理
      return Promise.reject(error);
    }
  );

  service.interceptors.response.use(response => {
    // 在响应接收之后，可以对响应数据进行处理
    // console.log(response);
      if(response.data.result!=0&&response.data.msg!=null){
        let msg = response.data.msg
        Notification.error({
          title: '提示',
          message: msg,
          position: 'top-left'
        });
        if(response.data.result==-2){
          userInfoStore().token=null;
          userInfoStore().user=null;
          userInfoStore().isLogin=false;
        }
      }
    return response;
  }, error => {
    // 在请求失败时，可以处理错误
    console.error(error);
    return Promise.reject(error);
  });

// 定义一个 Vue 插件
export default {
  install(Vue) {
    Vue.prototype.$axios = service; // 将 axios 实例挂载到 Vue 的原型上，以便在组件中使用
  }
};