<template>
  <el-container>
    <el-aside>
      <el-menu :default-active="appId + ''">
        <el-row type="flex" justify="start" style="margin-left: 16px; align-items: center; margin-right: 8px">
          <el-col>
            <h4>应用列表</h4>
          </el-col>
          <el-button v-if="isLogin()" type="primary" size="small" icon="el-icon-circle-plus-outline" @click="showAddDialog">创建应用</el-button>
        </el-row>
        <el-menu-item :index="app.id + ''" :key="app.id" v-for="app in appList" @click="clickApp(app)">
          <el-image :src="app.icon" style="width: 25px; height: 25px; margin-right: 8px"></el-image>
          <span>{{ app.name }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container v-if="appId >= 0">
      <el-aside>
        <el-menu :default-active="groupId + ''">
          <el-row type="flex" justify="start" style="margin-left: 16px; align-items: center; margin-right: 8px">
            <el-col>
              <h4>用例组</h4>
            </el-col>
            <el-upload :show-file-list="false" class="upload-demo" :action="'https://tester.eyewind.cn/api/group/import?appId=' + appId" style="margin-right: 16px" :on-success="handleAvatarSuccess" :before-upload="handlePreUpload" :on-error="handlerErrorUpload">
              <el-button v-loading.fullscreen.lock="fullscreenLoading" size="small" type="primary" icon="el-icon-upload">导入</el-button>
            </el-upload>
            <el-button v-if="isLogin()" type="primary" size="small" icon="el-icon-circle-plus-outline" @click="showGroupDialog">创建组</el-button>
          </el-row>
          <el-menu-item :index="group.id + ''" :key="group.id" v-for="group in groupList" @click="clickGroup(group)">
            <i class="el-icon-folder-opened"></i>
            <span>{{ group.name }}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <el-form :model="groupInfo" ref="groupForm" v-if="groupId" :inline="true">
          <el-form-item>
            <el-input v-model="groupInfo.name" placeholder="用例组名称"></el-input>
          </el-form-item>
          <el-form-item style="margin-left: 16px">
            <el-input v-model="groupInfo.details" placeholder="用例组描述"></el-input>
          </el-form-item>
          <el-form-item style="margin-left: 16px">
            <el-button v-if="isLogin()" type="primary" icon="el-icon-document-checked" @click="updateGroup()">保存</el-button>
            <el-button v-if="isLogin()" type="danger" icon="el-icon-delete" @click="deleteGroup()">删除</el-button>
          </el-form-item>
        </el-form>
        <el-col>
          <el-divider></el-divider>
        </el-col>

        <el-form :model="tempCases" :rules="rules" ref="ruleForm" v-if="isLogin() && groupId" style="width: 100%">
          <el-form-item prop="module">
            <el-col :span="8"><el-input v-model="tempCases.module" placeholder="模块"></el-input></el-col>
            <el-col :span="8" style="margin-left: 8px"><el-input v-model="tempCases.name" placeholder="用例名"></el-input></el-col>
            <el-col :span="3" style="margin-left: 8px">
              <el-select v-model="tempCases.priority" placeholder="优先级">
                <el-option v-for="item in stateList" :key="item.id" :label="item.value" :value="item.id"> </el-option>
              </el-select>
            </el-col>
            <el-col :span="3" style="margin-left: 8px"><el-button type="primary" icon="el-icon-circle-plus" @click="addCases()">添加</el-button></el-col>
          </el-form-item>
          <el-form-item prop="steps">
            <el-input v-model="tempCases.steps" placeholder="操作步骤"></el-input>
          </el-form-item>
          <el-form-item prop="outcome">
            <el-input v-model="tempCases.outcome" placeholder="期待结果"></el-input>
          </el-form-item>
          <el-form-item prop="details">
            <el-input v-model="tempCases.details" placeholder="备注"></el-input>
          </el-form-item>
        </el-form>
        <CasesTable ref="CasesTableRef" v-if="groupList.length > 0" :groupId="groupId" />
      </el-main>
      <AppEditDialog :visible="dialogVisible" :onClose="onCloseEditDialog" />
      <GroupEditDialog :appId="appId" :visible="groupDialogVisible" :onClose="onCloseGroupDialog" />
    </el-container>
  </el-container>
</template>
<script>
import CasesTable from "./GroupCasesTable.vue";
import AppEditDialog from "./AppEditDialog.vue";
import GroupEditDialog from "./GroupEditDialog.vue";
import { userInfoStore } from "../accout/user.js";

export default {
  components: {
    CasesTable,
    AppEditDialog,
    GroupEditDialog,
  },
  data() {
    return {
      fullscreenLoading: false,
      appId: parseInt(this.$route.query.appId),
      isCollapse: 1,
      appList: [],
      dialogVisible: false,
      groupDialogVisible: false,
      groupList: [],
      groupId: parseInt(this.$route.query.groupId),
      stateList: [
        {
          id: 0,
          value: "无",
        },
        {
          id: 1,
          value: "低",
        },
        {
          id: 2,
          value: "中",
        },
        {
          id: 3,
          value: "高",
        },
      ],
      tempCases: {
        priority: 3,
        module: "",
        name: "",
        steps: "",
        outcome: "",
        details: null,
      },
      rules: {
        module: [{ required: true, message: "请输入模块名称", trigger: "blur" }],
        name: [{ required: true, message: "请输入用例名称", trigger: "blur" }],
        steps: [{ required: true, message: "请输入操作步骤", trigger: "blur" }],
        outcome: [{ required: true, message: "请输入期待结果", trigger: "blur" }],
      },
      groupInfo: {},
    };
  },
  mounted() {
    this.getAppList();
    this.getGroupList();
    this.getGroupInfo();
  },
  methods: {
    getAppList() {
      this.$axios
        .get("api/app/list/group")
        .then((response) => {
          this.appList = response.data.data;
        })
        .catch((error) => {
          console.error(error.response.data);
        });
    },
    getGroupList() {
      if (!Number.isNaN(this.appId)) {
        this.$axios
          .get("api/group/list?appId=" + this.appId)
          .then((response) => {
            this.groupList = response.data.data;
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      }
    },
    getGroupInfo() {
      if (!Number.isNaN(this.groupId)) {
        this.$axios
          .get("api/group/get?groupId=" + this.groupId)
          .then((response) => {
            this.groupInfo = response.data.data[0];
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      }
    },
    clickApp(app) {
      this.appId = app.id;
      if (this.$route.query.appId != this.appId) {
        this.$router.push({
          query: { appId: app.id },
        });
        this.getGroupList();
      }
    },
    clickGroup(group) {
      this.groupId = group.id;
      this.getGroupInfo();
      if (this.$route.query.groupId != group.id) {
        this.$router.push({
          query: { appId: this.appId, groupId: group.id },
        });
      }
    },
    addCases() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          const article = {
            groupId: this.groupId,
            priority: this.tempCases.priority,
            module: this.tempCases.module,
            name: this.tempCases.name,
            steps: this.tempCases.steps,
            outcome: this.tempCases.outcome,
            details: this.tempCases.details,
          };
          console.log(article);
          this.$axios.post("api/cases/add", article).then(() => {
            this.$refs.CasesTableRef.fetchData();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    showAddDialog() {
      this.dialogVisible = true;
    },
    showGroupDialog() {
      this.groupDialogVisible = true;
    },
    onCloseEditDialog() {
      this.dialogVisible = false;
      this.getAppList();
    },
    onCloseGroupDialog() {
      this.groupDialogVisible = false;
      this.getGroupList();
    },
    isLogin() {
      return userInfoStore().isLogin;
    },
    updateGroup() {
      const data = {
        id: this.groupInfo.id,
        name: this.groupInfo.name,
        details: this.groupInfo.details,
      };
      this.$axios.post("api/group/update", data).then(() => {
        this.$router.go(0);
      });
    },
    deleteGroup() {
      this.$confirm("此操作将永久删除该测试用例组, 是否继续?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const article = { id: this.groupId };
          this.$axios.post("api/group/delete", article).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$router.push({
              query: { appId: this.appId },
            });
            this.$router.go(0);
          });
        })
        .catch(() => {});
    },
    handlePreUpload() {
      this.fullscreenLoading = true;
    },
    handleAvatarSuccess() {
      this.fullscreenLoading = false;
      this.$message({
        type: "success",
        message: "导入成功!",
      });
    },
    handlerErrorUpload() {
      this.fullscreenLoading = false;
      this.$message({
        type: "error",
        message: "导入出错，请联系管理员!",
      });
    },
  },
};
</script>
