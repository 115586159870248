<template>
  <el-container>
    <el-container>
      <el-aside>
        <el-row type="flex" justify="start" style="margin-left: 16px; margin-right: 8px; align-items: center">
          <el-col>
            <h4>测试计划</h4>
          </el-col>
          <el-button v-if="isLogin()" type="primary" size="small" icon="el-icon-circle-plus-outline" @click="showAddDialog">创建计划</el-button>
        </el-row>
        <div width="100px">
          <el-menu :default-active="planId + ''">
            <el-menu-item :key="plan.id" @click="selectPlan(plan)" v-for="plan in planList" :index="plan.id + ''">{{ plan.name }}</el-menu-item>
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <CasesPage
          v-if="planId > 0"
          ref="casesPageRef"
          :planId="planId"
          :planName="planName"
          :appId="appId"
          :myCallback="
            () => {
              getPlanList(appId);
            }
          "
        />
        <PlanEditDialog :planId="0" :visible="dialogVisible" :onClose="onCloseEditDialog" :appId="appId" />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import CasesPage from "./PlanCasesPage.vue";
import PlanEditDialog from "./PlanEditDialog.vue";
import { userInfoStore } from "../accout/user.js";
export default {
  props: {
    appId: Number,
  },
  data() {
    return {
      planId: parseInt(this.$route.query.planId),
      planName: "",
      appList: [],
      planList: [],
      dialogVisible: false,
    };
  },
  components: {
    CasesPage,
    PlanEditDialog,
  },
  mounted() {
    this.getPlanList(this.appId);
  },
  methods: {
    getPlanList(appId) {
      if (appId) {
        this.$axios
          .get("api/plan/list?appId=" + appId)
          .then((response) => {
            this.planList = response.data.data;
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      }
    },
    selectPlan(plan) {
      console.log("selectPlan:" + plan.id);
      this.planName = plan.name;
      this.planId = plan.id;
      if (this.$route.query.planId != plan.id) {
        this.$router.push({
          query: { appId: this.appId, planId: plan.id },
        });
      }
    },
    onCloseEditDialog() {
      this.dialogVisible = false;
      this.getPlanList(this.appId);
    },
    showAddDialog() {
      console.log("showAddDialog");
      this.dialogVisible = true;
    },
    isLogin() {
      return userInfoStore().isLogin;
    },
  },
};
</script>
