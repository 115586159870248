<template>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" @closed="onClose">
      <el-form :model="casesInfo" :rules="rules" ref="ruleForm" style="width: 50%">
        <el-form-item prop="module">
          <el-col :span="8"><el-input v-model="casesInfo.module" placeholder="模块"></el-input></el-col>
          <el-col :span="8" style="margin-left: 8px"><el-input v-model="casesInfo.name" placeholder="用例名"></el-input></el-col>
          <el-col :span="3" style="margin-left: 8px">
            <el-select v-model="casesInfo.priorityId" placeholder="优先级">
              <el-option v-for="item in stateList" :key="item.id" :label="item.value" :value="item.id"> </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item prop="steps">
          <el-input v-model="casesInfo.steps" placeholder="操作步骤"></el-input>
        </el-form-item>
        <el-form-item prop="outcome">
          <el-input v-model="casesInfo.outcome" placeholder="期待结果"></el-input>
        </el-form-item>
        <el-form-item prop="remark">
          <el-input v-model="casesInfo.remark" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" icon="el-icon-circle-plus" @click="submit(casesInfo)">{{ dialogButton }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    onClose: Function,
    casesId: Number,
  },
  watch: {
    visible: function (newVal) {
      this.dialogFormVisible = newVal;
      this.getCasesInfo();
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      tempCases: {
        priority: 3,
        module: "",
        name: "",
        steps: "",
        outcome: "",
        details: null,
      },
      rules: {
        module: [{ required: true, message: "请输入模块名称", trigger: "blur" }],
        name: [{ required: true, message: "请输入用例名称", trigger: "blur" }],
        steps: [{ required: true, message: "请输入操作步骤", trigger: "blur" }],
        outcome: [{ required: true, message: "请输入期待结果", trigger: "blur" }],
      },
      stateList: [
        {
          id: 0,
          value: "无",
        },
        {
          id: 1,
          value: "低",
        },
        {
          id: 2,
          value: "中",
        },
        {
          id: 3,
          value: "高",
        },
      ],
      formLabelWidth: "120px",
      testerList: [],
      casesInfo: {
        priority: 3,
        module: "",
        name: "",
        steps: "",
        outcome: "",
        details: null,
      },
      dialogTitle: "编辑用例",
      dialogButton: "添 加",
    };
  },
  mounted() {},
  methods: {
    getCasesInfo() {
      if (this.casesId > 0) {
        this.$axios
          .get("api/cases/get?id=" + this.casesId)
          .then((response) => {
            if (response.data.result == 1) {
              this.casesInfo = response.data.data[0];
              this.dialogTitle = "编辑测试用例";
              this.dialogButton = "编 辑";
            } else {
              this.dialogTitle = "添加测试用例";
              this.dialogButton = "添 加";
            }
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      } else {
        this.dialogTitle = "添加测试用例";
        this.dialogButton = "添 加";
      }
    },
    submit(row) {
      const article = {
        id: row.id,
        priority: row.priorityId,
        module: row.module,
        name: row.name,
        steps: row.steps,
        outcome: row.outcome,
        details: row.remark,
      };
      console.log(article);
      this.$axios.post("api/cases/edit", article).then(() => {
        this.dialogFormVisible = false;
      });
    },
  },
};
</script>
